import 'keen-slider/keen-slider.min.css'
import KeenSlider from 'keen-slider'

// import PhotoSwipeLightbox from 'photoswipe/lightbox'
import PhotoSwipeLightbox from 'photoswipe/dist/photoswipe-lightbox.esm.js'
import 'photoswipe/dist/photoswipe.css'

import componentMixin from '../../js/componentMixin'

export default {
  name: 'Slider',
  mixins: [componentMixin],
  props: {
    title: {
      type: String,
      default () {
        return 'Photos'
      }
    },
    images: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data: function () {
    return {
      slider: null,
      current: 0
    }
  },
  watch: {
    images () {
      this.$nextTick(() => {
        this.initSlider()
      })
    }
  },
  mounted () {
    this.initSlider()
  },
  unmounted () {
    if (this.slider) this.slider.destroy()
    if (this.pswp) this.pswp.destroy()
  },
  methods: {
    initSlider () {
      if (this.slider) this.slider.destroy()
      if (this.pswp) this.pswp.destroy()

      this.slider = new KeenSlider(this.$refs.slider, {
        mode: 'free-snap',
        slides: {
          initial: this.current,
          // origin: 'center',
          perView: window.innerWidth > 813 ? 2.5 : 1,
          spacing: 30
        },
        detailsChanged: (s) => {
          this.current = s.track.details.abs
        }
      })

      this.pswp = new PhotoSwipeLightbox({
        gallery: this.$refs.slider,
        children: 'a',
        pswpModule: () => import('photoswipe'),
        padding: { top: 48, bottom: 48, left: 48, right: 48 },
        showHideAnimationType: 'fade',
        bgOpacity: 0.9
      })

      this.pswp.init()
    },

    goTo (i = 0) {
      this.slider.moveToIdx(i, false, { duration: 800 })
    },
    imageLoaded (ev) {
      // get with and height of the image loaded
      ev.target.parentElement.dataset.pswpWidth = ev.target.naturalWidth
      ev.target.parentElement.dataset.pswpHeight = ev.target.naturalHeight
    }
  },
  beforeUnmount () {
    if (this.slider) this.slider.destroy()
  }
}
