<template>
  <div class="switch-plane">
    <select v-model="currentAircraftID" class="switch-plane__select">
      <option v-for="el in listAircrafts" :key="el._id" :value="el._id">
        {{ el.registration }}
      </option>
    </select>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'SwitchLang',
  data () {
    return {
      currentAircraftID: null
    }
  },
  watch: {
    currentAircraft: {
      handler (val) {
        this.currentAircraftID = val._id
      },
      immediate: true
    },
    currentAircraftID: {
      handler (val) {
        this.setCurrentAircraft(val)
      },
      immediate: true
    }
  },

  computed: {
    ...mapGetters('aircrafts', {
      listAircrafts: 'listAricrafts',
      currentAircraft: 'currentAircraft'
    })
  },
  methods: {
    ...mapActions('aircrafts', {
      setCurrentAircraft: 'setCurrentAircraft'
    })
  }
}
</script>

<style lang="less" scoped>
.switch-plane {
  margin-left: 24px;
  margin-top: auto;
  margin-bottom: auto;

  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: transparent;

  cursor: pointer;

  &:hover {
    background-color: fade(#fff, 10%);
  }

  &__select {
    appearance: none;

    background: url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='24' height='24' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='white'/></g></svg>") no-repeat;
    background-size: 14px;
    background-position: right 7px top 50%;
    padding-right: 48px;

    padding: 8px 28px 8px 10px;

    color: #fff;
    text-transform: uppercase;
    border: none;
    outline: none;
    cursor: pointer;
  }
}
</style>
