<template>
  <div class="financial-table" :class="{ 'is-blue': isBlue, 'only-one-data': data.columns.length === 1 }">
    <table class="financial-table__list" v-for="(list, keyList) in data.list" :key="keyList">
      <colgroup>
        <col class="col-main" />
        <col v-for="col in (3 - data.columns.length)" :key="col" class="col-value col-empty" />
        <col v-for="col in data.columns" :key="col" class="col-value" />
        <col style="width: 0px" />
      </colgroup>

      <tbody>
        <tr>
          <th>{{ $t(list.name) }}</th>
          <th v-for="col in (3 - data.columns.length)" :key="col" class="col-empty" />
          <th v-for="col in data.columns" :key="col">{{ col }}</th>
        </tr>
      </tbody>

      <tbody>
        <tr v-for="(d, keyData) in list.datas.filter((d) => d.values[0] !== '0' && d.values[0] !== '0.00')"
          :key="keyData" :data-v="d.name" ref="lines" :class="{ 'has-more-info': 'entries' in d && d.entries.length }">
          <!-- d.entries && d.entries.reduce((acc, cur) => acc + (isNaN(cur.debits) ? 0 : cur.debits), 0) > 0 -->
          <td><span>{{ $t(d.name) }}</span></td>
          <td v-for="col in (3 - data.columns.length)" :key="col" class="col-empty" />
          <td v-for="(value, keyValue) in d.values" :key="keyValue"><span>{{ value.includes('h') ? value :
            Number(value.replaceAll(',',
              '')).toLocaleString('fr', { maximumFractionDigits: 0 }) }}{{ getSymbol(keyValue) }}</span>
          </td>
          <div v-if="'entries' in d && d.entries.length" class="col-more-info">
            <div class="col-more-info__line is-head">
              <div class="line__title">{{ $t('name') }}</div>
              <div class="line__ref">{{ $t('ref') }}</div>
              <div class="line__value">€</div>
            </div>
            <a v-for="(entry) in d.entries" :key="entry.reference"
              :href="entry.external_link ? entry.external_link : null" target="_blank" class="col-more-info__line"
              :class="{ 'no-data': entry.debits === 0 && entry.credits === 0, 'has-link': entry.external_link }">
              <div class="line__title">{{ entry.descr || 'NO TITLE' }}</div>
              <div class="line__ref">{{ entry.reference || 'NO REF' }}</div>
              <div v-if="entry.debits > 0" class="line__value">{{ entry.debits.toLocaleString('fr',
                { maximumFractionDigits: 0 }) }} €</div>
              <div v-else class="line__value">{{ (entry.credits * -1).toLocaleString('fr', { maximumFractionDigits: 0 })
                }} €</div>
            </a>
          </div>
        </tr>
        <tr v-if="list.total">
          <th>{{ list.total.name }}</th>
          <th v-for="(value, keyValue) in list.total.values" :key="keyValue">{{ value }}{{ getSymbol(keyValue) }}</th>
        </tr>
      </tbody>
    </table>

    <div v-if="data.totalList" class="financial-table__total">
      <table class="financial-table__list">
        <colgroup>
          <col class="col-main" />
          <col v-for="col in data.columns" :key="col" class="col-value" />
        </colgroup>
        <thead>
          <tr>
            <th></th>
            <th v-for="col in data.columns" :key="col">{{ col }}</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>{{ data.totalList.name }}</td>
            <td v-for="(value, keyValue) in data.totalList.values" :key="keyValue">{{ value }} {{ getSymbol(keyValue) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import './style.less'

export default {
  name: 'FinancialTable',
  props: {
    data: {
      type: Object,
      required: true
    },
    isBlue: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      currentLine: null,
      requestAnimationFrameFunctions: {}
    }
  },
  watch: {
    data: {
      handler: function () {
        this.$nextTick(() => setTimeout(this.initLinesEvent, 100))
      },
      deep: true
    }
  },
  mounted () {
    this.initLinesEvent()
    this.initRequestAnimationFrame()
  },
  methods: {
    getSymbol (value) {
      if (!this.data.columnsSign) return ''
      return this.data.columnsSign[value]
    },

    initLinesEvent () {
      if (!this.$refs.lines) return

      this.$refs.lines.forEach((line, index) => {
        if (!line.classList.contains('has-more-info')) return
        const listFixedElements = line.querySelectorAll('td span')
        this.calculateHeight(line)

        line.removeEventListener('click', this.handleHoverLine)
        line.addEventListener('click', this.handleHoverLine)

        // set position
        this.setSizesFixedElements(line, listFixedElements)
        window.removeEventListener('resize', this.setSizesFixedElements.bind(this, line, listFixedElements))
        window.addEventListener('resize', this.setSizesFixedElements.bind(this, line, listFixedElements))
      })
    },
    calculateHeight (line) {
      const colMore = line.querySelector('.col-more-info')
      if (!colMore) return

      const heightMore = colMore.getBoundingClientRect().height
      line.style.setProperty('--height-more', `${heightMore}px`)
    },
    handleHoverLine (ev) {
      const line = ev.currentTarget

      if (!ev.target.parentNode.parentNode.classList.contains('has-more-info')) return false
      const listFixedElements = line.querySelectorAll('td span')
      const nameFunc = 'line' + line.dataset.v

      this.calculateHeight(line)

      // this.currentLine = line
      const isOpen = line.classList.toggle('is-open')

      if (isOpen) {
        const startScroll = window.scrollY + line.getBoundingClientRect().top - document.querySelector('#nav').getBoundingClientRect().height
        const endScroll = startScroll + line.getBoundingClientRect().height - 30

        const func = () => {
          const isFixed = window.scrollY >= startScroll && window.scrollY <= endScroll

          listFixedElements.forEach((el) => {
            el.classList.toggle('is-fixed', isFixed)
          })
        }

        func.call()

        this.requestAnimationFrameFunctions[nameFunc] = func
      } else {
        delete this.requestAnimationFrameFunctions[nameFunc]
      }
    },
    setSizesFixedElements (line, els) {
      const navHeight = document.querySelector('#nav').getBoundingClientRect().height

      els.forEach((el, i) => {
        el.style.width = ''
        el.style.height = ''
        el.style.left = ''
        el.style.top = ''

        this.$nextTick(() => {
          const rect = el.getBoundingClientRect()
          el.style.width = rect.width + 'px'
          el.style.height = rect.height + 'px'
          el.style.left = rect.left + 5 + 'px'

          if (i === 0) {
            line.style.setProperty('--width-first', `${rect.width + 10}px`)
          }

          el.style.top = navHeight + 5 + 'px'
        })
      })
    },

    initRequestAnimationFrame () {
      window.addEventListener('scroll', () => {
        // boucle on this.requestAnimationFrameFunctions
        for (const key in this.requestAnimationFrameFunctions) {
          const func = this.requestAnimationFrameFunctions[key]
          func.call()
        }
      })
    }
  }
}
</script>
