<template>
  <div v-if="crew && crew.length" class="block crew-block">
    <div class="crew-block-top">
      <h3>{{ $t('flight-crew') }}</h3>
      <div class="crew-block-top-more" :class="seeMore ? '-seeMore' : ''" v-if="crew.length > 2" @click="toggleSeeMore">
        <span> {{ $t('see-more') }}</span>
        <span> {{ $t('see-less') }}</span>
      </div>
    </div>
    <ul class="crew-block-list" :class="crew.length > 2 ? 'moreThan2' : ''">
      <li class="crew-block-list-item" v-for="(item, index) in crew" :class="index < 2 || seeMore ? '-see' : ''"
        :key="item._id">
        <component :is="item.resume ? 'a' : 'div'" :href="`${mediaPrefix}${item.resume}`" target="_blank">
          <div class="img-container">
            <img :src="`${mediaPrefix}${item.profile}`" />
          </div>
          <h4>{{ item.name }}</h4>
          <p>{{ item.position }}</p>
        </component>
      </li>
    </ul>
  </div>
</template>

<script src="./script.js"></script>
<style src="./style.less" lang="less"></style>
